<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

function partitionTestimonialCards(cards: any[], size: number) {
    const result: any[] = Array.from({ length: size }, () => []);
    for (let i = 0; i < cards.length; i++) {
        result[i % size].push(cards[i]);
    }
    return result;
}

const partitions = computed(() => partitionTestimonialCards(props.blok.cards, 3));
</script>

<template>
    <!-- Block start -->
    <section class="bg-white dark:bg-midnight-blue" :id="blok.id ? blok.id : null">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class="mx-auto max-w-screen-md text-center">
                <h2
                    class="mb-4 text-gray-900 dark:text-white"
                >
                    {{ blok.title }}
                </h2>
                <p class="mb-8 font-light text-gray-500 lg:mb-16 dark:text-gray-400 sm:text-xl">
                    {{ blok.description }}
                </p>
            </div>
            <div class="grid gap-8 lg:grid-cols-3">
                <div class="space-y-6" v-for="cards in partitions">
                    <StoryblokComponent v-for="item in cards" :key="item._uid" :blok="item" />
                </div>
            </div>
        </div>
    </section>
    <!-- Block end -->
</template>
